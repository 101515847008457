import React, { useState, useEffect, useContext } from 'react';
import api from '../api';
import './Settings.css';
import PricingPopup from './PricingPopup';
import { AuthContext } from '../Login/AuthContext';
import ConfirmDialog from '../UI/ConfirmDialog';
import Toast from '../UI/Toast';

const Settings = ({ onOpenPricing }) => {
    const { subscription, setSubscription, user } = useContext(AuthContext);
    const [openaiKey, setOpenaiKey] = useState('');
    const [maskedKey, setMaskedKey] = useState('');
    const [isKeySet, setIsKeySet] = useState(false);
    const [wordCount, setWordCount] = useState({ current: 0, max: 0 });

    const [userInfo, setUserInfo] = useState({
        firstName: '',
        lastName: '',
        email: user?.email || '',
    });

    const [passwords, setPasswords] = useState({
        newPassword: '',
        confirmNewPassword: '',
    });


    const [customStyles, setCustomStyles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [toast, setToast] = useState({ show: false, message: '', type: 'success' });

    const formatDate = (timestamp) => {
        const date = new Date(timestamp * 1000); // Convert timestamp to milliseconds if it's in seconds
        return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
    };

    const handleManageSubscription = async () => {

        try {
            const response = await api.get(`/stripe_dashboard`);
            window.location.href = response.data.dashboard_url;
        } catch (error) {
            console.error('Stripe:', error);
        }
    };

    // Gère la mise à jour des informations de compte
    const handleAccountInfoChange = (e) => {
        setUserInfo({
            ...userInfo,
            [e.target.name]: e.target.value,
        });
    };

    const updateAccountInfo = async () => {
        try {
            await api.post('/update_account_info', userInfo);
            alert('Account information updated successfully!');
        } catch (error) {
            console.error('Error updating account info:', error);
        }
    };

    // Gère la mise à jour du mot de passe
    const handlePasswordChange = (e) => {
        setPasswords({
            ...passwords,
            [e.target.name]: e.target.value,
        });
    };

    const updatePassword = async () => {
        // Validation de base côté client
        if (!passwords.newPassword || !passwords.confirmNewPassword) {
            setToast({
                show: true,
                message: 'Please fill in all password fields',
                type: 'error'
            });
            return;
        }

        if (passwords.newPassword.length < 8) {
            setToast({
                show: true,
                message: 'Password must be at least 8 characters long',
                type: 'error'
            });
            return;
        }

        if (passwords.newPassword !== passwords.confirmNewPassword) {
            setToast({
                show: true,
                message: 'Passwords do not match',
                type: 'error'
            });
            return;
        }

        try {
            const response = await api.post('/update_password', passwords);
            
            if (response.data.result === "OK") {
                setToast({
                    show: true,
                    message: response.data.message || 'Password updated successfully!',
                    type: 'success'
                });
                // Reset fields
                setPasswords({ newPassword: '', confirmNewPassword: '' });
            } else {
                setToast({
                    show: true,
                    message: response.data.message || 'Failed to update password',
                    type: 'error'
                });
            }
        } catch (error) {
            setToast({
                show: true,
                message: error.response?.data?.message || 'Error updating password. Please try again.',
                type: 'error'
            });
            console.error('Error updating password:', error);
        }
    };


    useEffect(() => {
        const fetchOpenAIKey = async () => {
            console.log("Fetching OpenAI key");
            try {
                const response = await api.get('/get_openai_key');
                console.log("Response : " + response.data);
                if (response.data.key) {
                    console.log("OpenAI key fetched : " + response.data.key);
                    setIsKeySet(true);
                    setMaskedKey(maskApiKey(response.data.key));
                    setOpenaiKey(response.data.key);
                }
            } catch (error) {
                console.error('Error fetching OpenAI key:', error);
            }
        };

        if (user?.byok) {
            fetchOpenAIKey();
        }
    }, [user]);

    const maskApiKey = (key) => {
        if (!key) return '';
        const visibleStart = key.substring(0, 10);
        const maskedPart = '*'.repeat(key.length - 10);
        return `${visibleStart}${maskedPart}`;
    };

    const handleOpenAIKeySubmit = async () => {
        try {
            const response = await api.post('/update_openai_key', { key: openaiKey });
            if (response.data.result === "OK") {
                setMaskedKey(maskApiKey(openaiKey));
                setIsKeySet(true);
                alert(response.data.message);
            } else {
                alert(response.data.message);
            }
        } catch (error) {
            console.error('Error updating OpenAI key:', error);
            alert(error.response?.data?.message || 'Failed to update OpenAI API key');
        }
    };

    const handleDeleteAccount = async () => {
        try {
            await api.delete('/delete_account');
            localStorage.removeItem('token');
            window.location.href = '/';
        } catch (error) {
            console.error('Error deleting account:', error);
            alert('Failed to delete account. Please try again.');
        }
    };

    useEffect(() => {
        const fetchWordCount = async () => {
            try {
                const response = await api.get('/generate/word_count');
                setWordCount({
                    current: response.data.wordCount,
                    max: subscription?.plan?.word_count_limit || 0
                });
            } catch (err) {
                console.error('Error fetching word count:', err);
            }
        };

        if (subscription?.active) {
            fetchWordCount();
        }
    }, [subscription]);

    return (
        <div className="settings-container">

            {/* Gestion de l'abonnement */}
            <div className="panel setting-panel">
                <h3>Subscription</h3>
                <p>Manage your subscription.</p>

                {(subscription && subscription.active) || user?.byok ? (
                    <>
                        {user?.byok ? (
                            <span className="subscription-state active-subscription">
                                <span className="material-symbols-outlined icon">check</span> Lifetime Deal | Bring Your Own Key
                            </span>
                        ) : subscription.cancelled ? (
                            <span className="subscription-state cancelled-subscription">
                                <span className="material-symbols-outlined icon">cancel</span> {subscription.plan?.name || 'Premium'} | Cancelled : {formatDate(subscription.end_date)}
                            </span>
                        ) : (
                            <span className="subscription-state active-subscription">
                                <span className="material-symbols-outlined icon">check</span> {subscription.plan?.name || 'Premium'} | Next payment : {formatDate(subscription.end_date)}
                            </span>
                        )}

                        <div className="subscription-details">
                            {(subscription?.plan?.word_count_limit > 0 || user?.byok) && (
                                <div className="subscription-word-count">
                                    <div className="word-count-stats">
                                        <div className="stat-item">
                                            <span className="stat-label">Words used</span>
                                            <span className="stat-value">{wordCount.current.toLocaleString()}</span>
                                        </div>
                                        <div className="stat-item">
                                            <span className="stat-label">Words remaining</span>
                                            <span className="stat-value">{user?.byok ? '∞' : (wordCount.max - wordCount.current).toLocaleString()}</span>
                                        </div>
                                        <div className="stat-item">
                                            <span className="stat-label">Monthly limit</span>
                                            <span className="stat-value">{user?.byok ? '∞' : wordCount.max.toLocaleString()}</span>
                                        </div>
                                    </div>
                                    {!user?.byok && (
                                        <div className="progress-container">
                                            <div
                                                className="progress-bar"
                                                style={{
                                                    width: `${(wordCount.current / wordCount.max) * 100}%`,
                                                    backgroundColor: `${wordCount.current / wordCount.max > 0.9 ? 'var(--color-danger)' : 'var(--color-primary-0)'}`
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}

                            <div className="plan-features">
                                <h4>Features:</h4>
                                <ul>
                                    <li><span className="material-symbols-outlined">check</span> The latest OpenAI model for better content</li>
                                    <li><span className="material-symbols-outlined">check</span> YouTube to Blog article</li>
                                    <li><span className="material-symbols-outlined">check</span> YouTube to Newsletter</li>
                                    <li><span className="material-symbols-outlined">check</span> YouTube to Tweets/X</li>
                                    <li><span className="material-symbols-outlined">check</span> YouTube to LinkedIn</li>
                                    <li><span className="material-symbols-outlined">check</span> YouTube video summarize tool</li>
                                    <li><span className="material-symbols-outlined">check</span> YouTube to Custom prompt tool</li>
                                    <li><span className="material-symbols-outlined">check</span> 30+ Languages</li>
                                </ul>
                            </div>
                        </div>

                        {!user?.byok && (
                            <button
                                type="button"
                                className="button-plain"
                                onClick={handleManageSubscription}
                            >
                                <span className="material-symbols-outlined icon">manage_accounts</span> Manage subscription
                            </button>
                        )}
                    </>
                ) : (
                    <>
                        <p>You are on the <strong>Free</strong> plan.</p>
                        <button className="button-important" onClick={onOpenPricing}>
                            <div className="inner">
                                <span className="material-symbols-outlined icon">bolt</span>
                                Upgrade to Premium
                            </div>
                        </button>

                        <p className="info-text">Premium unlocks unlimited generations with latest AI models <a href="#" onClick={onOpenPricing}>See all features</a></p>


                    </>)}
            </div>

            {user && user.byok && (
                <div className="panel setting-panel">
                    <h3>OpenAI API Key</h3>
                    <p>Add your own OpenAI API key to use with our service.</p>

                    <div className="form-group">
                        <label className="form-label">API Key</label>
                        {isKeySet ? (
                            <input
                                type="text"
                                className="form-control"
                                value={openaiKey}
                                onChange={(e) => setOpenaiKey(e.target.value)}
                                placeholder="sk-..."
                            />
                        ) : (
                            <input
                                className="form-control"
                                value={openaiKey}
                                onChange={(e) => setOpenaiKey(e.target.value)}
                                placeholder="sk-..."
                            />
                        )}
                    </div>

                    <button
                        className="button-plain"
                        onClick={handleOpenAIKeySubmit}
                        disabled={!openaiKey}
                    >
                        <span className="material-symbols-outlined icon">key</span>
                        {isKeySet ? 'Update API Key' : 'Save API Key'}
                    </button>
                </div>
            )}

            {/* Infos du compte */}
            <div className="panel setting-panel">
                <h3>Account Info</h3>
                <p>Update your account information.</p>
                <form>
                 
                    <div className="form-group">
                        <label className="form-label" htmlFor="email">Email:</label>
                        <input
                            className="form-control"
                            type="text"
                            id="email"
                            name="email"
                            value={user?.email}
                            readOnly
                            disabled
                        />
                    </div>
                </form>
            </div>

            {/* Changement de mot de passe */}
            <div className="panel setting-panel">
                <h3>Update Password</h3>
                <p>Define or change your account's password.</p>
                <form>
                    <div className="form-group">
                        <label className="form-label" htmlFor="newPassword">New Password:</label>
                        <input
                            className="form-control"
                            type="password"
                            id="newPassword"
                            name="newPassword"
                            value={passwords.newPassword}
                            onChange={handlePasswordChange}
                        />
                    </div>
                    <div className="form-group">
                        <label className="form-label" htmlFor="confirmNewPassword">New Password Confirmation:</label>
                        <input
                            className="form-control"
                            type="password"
                            id="confirmNewPassword"
                            name="confirmNewPassword"
                            value={passwords.confirmNewPassword}
                            onChange={handlePasswordChange}
                        />
                    </div>
                    <button
                        type="button"
                        className="button-plain"
                        onClick={updatePassword}
                    >
                        <span className="material-symbols-outlined icon">lock</span> Update Password
                    </button>
                </form>
            </div>



            {/* Gestion de l'account */}
            <div className="panel setting-panel">
                <h3>Account</h3>
                <p>Manage your account.</p>
                <div className="account-actions">
                    <button className="button-hollow" onClick={() => {
                        localStorage.removeItem('token');
                        window.location.reload();
                    }}>
                        <span className="material-symbols-outlined icon">logout</span> Logout
                    </button>
                    <button className="button-plain" onClick={() => setShowDeleteConfirm(true)}>
                        <span className="material-symbols-outlined icon">delete_forever</span> Delete Account
                    </button>
                </div>
            </div>

            <ConfirmDialog
                isOpen={showDeleteConfirm}
                onClose={() => setShowDeleteConfirm(false)}
                onConfirm={handleDeleteAccount}
                title="Delete Account"
                message="Are you sure you want to delete your account? This action cannot be undone and will result in:
                        - Permanent deletion of all your generated content
                        - Cancellation of your subscription if active
                        - Removal of all your data from our servers"
            />

            {toast.show && (
                <Toast
                    message={toast.message}
                    type={toast.type}
                    onClose={() => setToast({ ...toast, show: false })}
                />
            )}

        </div>
    );
};

export default Settings;
