import { Link } from 'react-router-dom';

import './Navbar.css'

const Navbar = () => {

    return (

        <div className="navbar">
            <div className="navbar-container">
                <div className="navbar-logo">
                    <img src="/logo_dark.png" alt="EchoNote Logo" />
                </div>

                <div className="navbar-links">
                    <a href="/">Home</a>
                    <a href="/blog">Blog</a>
                    <a href="/pricing">Pricing</a>
                </div>

                <div className="navbar-buttons">
                    <Link to="/login" className="no-style-link">
                        <button className="button-plain">
                        <span className="material-symbols-outlined icon">login</span>
                            Login
                            </button>
                    </Link>
                    <Link to="/signup" className="no-style-link">
                        <button className="button-hollow">
                        <span className="material-symbols-outlined icon">signature</span>
                            Signup
                            </button>
                    </Link>
                </div>
            </div>
        </div>
    )

}

export default Navbar;