import React from 'react';
import Generator from './Generator';
import LanguageSelect from './LanguageSelect';

const CustomGenerator = ({ onOpenPricing }) => {
    const formFields = [
        {
            id: 'custom_prompt',
            type: 'textarea',
            label: 'Custom Prompt',
            placeholder: 'Enter your custom prompt here...',
            rows: 8
        },
        {
            id: 'model',
            type: 'select',
            label: 'AI Model',
            defaultValue: 'openai',
            options: [
                { value: 'openai', label: 'OpenAI (GPT-4)' }
            ]
        }
    ];

    return (
        <Generator
            title={<><span className="highlight">YT</span> to Custom</>}
            icon="bi bi-chat-left-quote-fill icon"
            formFields={formFields}
            generateEndpoint="/generate/custom_prompt"
            transformFormData={(data) => ({
                url: data.url,
                custom_prompt: data.custom_prompt,
                model: data.model
            })}
            onOpenPricing={onOpenPricing}
        />
    );
};

export default CustomGenerator; 