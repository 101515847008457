import React, { useState, useContext, useEffect } from 'react';
import api from '../../api';
import { AuthContext } from '../../Login/AuthContext';
import { marked } from 'marked';
import './Generator.css';
import UpgradePrompt from './UpgradePrompt';
import { Link } from 'react-router-dom';

const Generator = ({
    title,
    icon,
    formFields,
    generateEndpoint,
    transformFormData,
    skipSubscriptionCheck = false,
    onOpenPricing
}) => {
    const { user, subscription } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [videoInfo, setVideoInfo] = useState(null);
    const [generatedContent, setGeneratedContent] = useState('');
    const [error, setError] = useState('');
    const [hasAccess, setHasAccess] = useState(false);
    const [wordCount, setWordCount] = useState({ current: 0, max: 0 });
    const [showPricing, setShowPricing] = useState(false);

    const initialFormData = {
        url: '',
        ...formFields.reduce((acc, field) => ({
            ...acc,
            [field.id]: field.defaultValue
        }), {})
    };

    const [formData, setFormData] = useState(initialFormData);

    const checkAccess = async () => {
        try {
            const response = await api.get('/generate/check_access');
            setHasAccess(response.data.status === 'access_granted' || response.data.status === 'trial_available');
        } catch (err) {
            setHasAccess(false);
        }
    };

    const updateWordCount = async () => {
        try {
            const response = await api.get('/generate/word_count');
            setWordCount({
                current: response.data.wordCount,
                max: response.data.maxCount
            });
        } catch (err) {
            console.error('Error fetching word count:', err);
        }
    };

    const getVideoInfo = async () => {
        try {
            const response = await api.post('/generate/youtube_video_info', {
                url: formData.url
            });

            if (response.data.result === "OK") {
                setVideoInfo(response.data.video_info);
            }
        } catch (err) {
            console.error('Error fetching video info:', err);
        }
    };

    useEffect(() => {
        checkAccess();
        updateWordCount();
    }, []);

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        });
    };

    const generateContent = async () => {
        setIsLoading(true);
        setError('');

        if (!hasAccess) {
            setError('You need to upgrade to access this feature');
            setIsLoading(false);
            return;
        }

        try {
            const transformedData = transformFormData ? transformFormData(formData) : formData;
            const response = await api.post(generateEndpoint, transformedData);
            const data = response.data;

            if (data.result === "OK" && data.text) {
                setGeneratedContent(data.text);
                getVideoInfo();
                updateWordCount();
            } else {
                setError(
                    data.error && data.details 
                        ? `${data.error} - ${data.details}` 
                        : data.error || data.details || data.message || 'An error occurred'
                );
            }
        } catch (err) {
            
            const errorData = err.response?.data;
            setError(
                errorData?.error && errorData?.details
                    ? `${errorData.error} - ${errorData.details}`
                    : errorData?.error || errorData?.details || errorData?.message || 'An error occurred during generation'
            );
        } finally {
            setIsLoading(false);
        }
    };

    const copyText = () => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = generatedContent;
        navigator.clipboard.writeText(tempDiv.textContent);
    };

    const copyHtml = () => {
        navigator.clipboard.writeText(generatedContent);
    };

    const formatContent = (content) => {
        const seemsMarkdown = content.includes('#') || 
                            content.includes('**') || 
                            content.includes('__') ||
                            content.includes('```') ||
                            content.includes('- ') ||
                            content.includes('1. ');

        if (seemsMarkdown) {
            try {
                return marked(content);
            } catch (e) {
                console.warn('Markdown parsing failed, returning raw content');
                return content;
            }
        }

        return content;
    };

    if ((!subscription?.active && !user?.byok) && !skipSubscriptionCheck) {
        return <UpgradePrompt onOpenPricing={onOpenPricing} />;
    }

    return (
        <div className="generator panel">
            <h2><i className={`${icon}`}></i> {title}</h2>
            <div className="generator-container">
                <div className="form-panel">
                    {(!user?.byok && wordCount.max > 0) || user?.byok ? (
                        <div className="word-count-container">
                            <div className="word-count-stats">
                                <div className="stat-item">
                                    <span className="stat-label">Used words</span>
                                    <span className="stat-value">{wordCount.current}</span>
                                </div>
                                <div className="stat-item">
                                    <span className="stat-label">Remaining words</span>
                                    <span className="stat-value">{user?.byok ? '∞' : wordCount.max - wordCount.current}</span>
                                </div>
                                <div className="stat-item">
                                    <span className="stat-label">Total available</span>
                                    <span className="stat-value">{user?.byok ? '∞' : wordCount.max}</span>
                                </div>
                            </div>
                            <div className="progress-container">
                                <div
                                    className="progress-bar"
                                    style={{
                                        width: user?.byok ? '0%' : `${(wordCount.current / wordCount.max) * 100}%`,
                                        backgroundColor: `${wordCount.current / wordCount.max > 0.9 ? 'var(--color-danger)' : 'var(--color-primary-0)'}`
                                    }}
                                />
                            </div>
                        </div>
                    ) : null}

                    {error && <div className="error-message" style={{ marginBottom: '30px' }}>{error}</div>}

                    <div className="form-group">
                        <label>YouTube URL</label>
                        <input
                            type="text"
                            id="url"
                            className="form-control"
                            value={formData.url}
                            onChange={handleInputChange}
                            placeholder="https://www.youtube.com/watch?v=..."
                        />
                    </div>

                    {formFields.map(field => (
                        <div key={field.id} className="form-group">
                            <label htmlFor={field.id}>{field.label}</label>
                            
                            {field.type === 'textarea' ? (
                                <textarea
                                    id={field.id}
                                    className="form-control"
                                    placeholder={field.placeholder}
                                    rows={field.rows}
                                    value={formData[field.id] || ''}
                                    onChange={(e) => handleInputChange({
                                        target: {
                                            id: field.id,
                                            value: e.target.value
                                        }
                                    })}
                                />
                            ) : field.type === 'select' ? (
                                <select
                                    id={field.id}
                                    className="form-select"
                                    value={formData[field.id]}
                                    onChange={handleInputChange}
                                >
                                    {field.options.map(option => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            ) : field.type === 'checkbox' ? (
                                <input
                                    type="checkbox"
                                    id={field.id}
                                    checked={formData[field.id]}
                                    onChange={handleInputChange}
                                />
                            ) : (
                                <input
                                    type={field.type}
                                    id={field.id}
                                    className="form-control"
                                    value={formData[field.id]}
                                    onChange={handleInputChange}
                                    placeholder={field.placeholder}
                                />
                            )}
                        </div>
                    ))}

                    <button
                        className="button-plain"
                        onClick={generateContent}
                        disabled={isLoading}
                    >
                        Generate content
                    </button>


                </div>

                <div className="output-section">
                    {videoInfo && (
                        <div className="video-info">
                            <img src={videoInfo.thumbnail_url} alt="Video thumbnail" />
                            <div>
                                <h4>{videoInfo.title}</h4>
                                <p>{videoInfo.channel_title}</p>
                            </div>
                        </div>
                    )}

                    {isLoading ? (
                        <div className="loader-container">
                            <div className="loader-spinner"></div>
                        </div>
                    ) : generatedContent ? (
                        <div className="generated-content">
                            <div className="content-actions">
                                <button className="button-hollow" onClick={copyText}>
                                    <i className="fas fa-clipboard"></i> Copy text
                                </button>
                                <button className="button-hollow" onClick={copyHtml}>
                                    <i className="fas fa-code"></i> Copy HTML
                                </button>
                            </div>
                            <div className="content">
                                <div 
                                    dangerouslySetInnerHTML={{ 
                                        __html: formatContent(generatedContent) 
                                    }} 
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="placeholder">
                            The generated content goes here
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Generator; 