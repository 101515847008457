import React from 'react';
import Generator from './Generator';
import LanguageSelect from './LanguageSelect';

const BlogGenerator = () => {
    const formFields = [
        LanguageSelect(false),
        {
            id: 'tone',
            type: 'select',
            label: 'Tone',
            defaultValue: 'Friendly',
            options: [
                { value: 'Friendly', label: 'Friendly' },
                { value: 'Inspirational', label: 'Inspirational' },
                { value: 'Entertaining', label: 'Entertaining' },
                { value: 'Professional', label: 'Professional' }
            ]
        },
        {
            id: 'style',
            type: 'select',
            label: 'Style',
            defaultValue: 'Informative',
            options: [
                { value: 'Informative', label: 'Informative' },
                { value: 'Analytical', label: 'Analytical' },
                { value: 'Storytelling', label: 'Storytelling' },
                { value: 'Technical', label: 'Technical' }
            ]
        },
        {
            id: 'length',
            type: 'select',
            label: 'Length',
            defaultValue: 'medium',
            options: [
                { value: 'short', label: 'Short (~300 words)' },
                { value: 'medium', label: 'Medium (~700 words)' },
                { value: 'long', label: 'Long (~1500 words)' }
            ]
        },
        {
            id: 'model',
            type: 'select',
            label: 'AI Model',
            defaultValue: 'openai',
            options: [
                { value: 'openai', label: 'OpenAI (GPT-4)' }
            ]
        }
    ];

    return (
        <Generator
            title={<><span className="highlight">YT</span> to Blog article</>}
            icon="fas fa-newspaper icon"
            formFields={formFields}
            generateEndpoint="/generate/youtube_article"
            transformFormData={(data) => ({
                url: data.url,
                language: data.language,
                tone: data.tone,
                style: data.style,
                length: data.length,
                model: data.model,
                long: false
            })}
            skipSubscriptionCheck={true}
        />
    );
};

export default BlogGenerator; 