// components/Login.js
import React, { useState } from 'react';
import api from '../api';
import './Login.css';
import GoogleLogin from './GoogleLogin';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginOption, setLoginOption] = useState('email_link');
    const [message, setMessage] = useState({ type: '', text: '' });
    const [showPassword, setShowPassword] = useState(false);
    const [isButtonLoading, setIsButtonLoading] = useState(false);

    const handleLoginWithPassword = async (e) => {
        e.preventDefault();
        setIsButtonLoading(true);
        try {
            const response = await api.post('/login', { email, password });
            localStorage.setItem('token', response.data.access_token);
            window.location.reload();
        } catch (error) {
            console.error('Login error:', error);
            setMessage({
                type: 'error',
                text: error.response?.data?.msg || 'An error occurred during login. Please try again.'
            });
        } finally {
            setIsButtonLoading(false);
        }
    };

    const handleLoginWithLink = async (e) => {
        e.preventDefault();
        setIsButtonLoading(true);
        try {
            await api.post('/send_login_link', { email });
            setMessage({
                type: 'success',
                text: 'A login link has been sent to your email address. Please check your inbox (and spam folder) to login.'
            });
        } catch (error) {
            console.error('Error sending login link:', error);
            setMessage({
                type: 'error',
                text: error.response?.data?.msg || 'Failed to send login link. Please try again or contact support.'
            });
        } finally {
            setIsButtonLoading(false);
        }
    };

    return (
        <div className="login-page">
            <div className="login-container">
                <img src="/logo_dark.png" alt="Logo" className="login-logo" />
                <h2>Login to your account</h2>
                <p>Enter your email and password to login</p>

                {loginOption === 'email_password' && (
                    <form onSubmit={handleLoginWithPassword}>
                        <div className="input-group">
                            <div className="icon-container">
                                <span className="material-symbols-outlined login-icon">mail</span>
                            </div>
                            <input
                                type="email"
                                placeholder="your@example.com"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className="input-group">
                            <div className="icon-container">
                                <span className="material-symbols-outlined login-icon">lock</span>
                            </div>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Your password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            <span className="material-symbols-outlined eye-icon" onClick={() => setShowPassword(!showPassword)}>
                                {showPassword ? 'visibility' : 'visibility_off'}
                            </span>
                        </div>

                        <div style={{ marginTop: '30px' }}>
                            <button className="button-plain" type="submit"><span className="material-symbols-outlined icon">login</span>Login</button>

                            <button className="button-hollow" type="button" onClick={() => setLoginOption('email_link')}><span className="material-symbols-outlined icon">email</span>Get one-time login link</button>
                        </div>
                    </form>
                )}

                {loginOption === 'email_link' && (
                    <form onSubmit={handleLoginWithLink}>
                        <div className="input-group">
                            <div className="icon-container">
                                <span className="material-symbols-outlined login-icon">mail</span>
                            </div>
                            <input
                                type="email"
                                placeholder="your@example.com"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div style={{ marginTop: '30px' }}>
                            <button className="button-plain" type="submit" disabled={isButtonLoading}>
                                <span className="material-symbols-outlined icon">email</span>
                                {isButtonLoading ? 'Sending...' : 'Send login link'}
                            </button>
                            <button className="button-hollow" type="button" onClick={() => setLoginOption('email_password')}>
                                <span className="material-symbols-outlined icon">lock</span>Login with password</button>
                        </div>
                    </form>
                )}

                <p className="login-toggle-text">
                    Don't have an account? <strong onClick={() => window.location.href = '/signup'}>Create an account</strong>
                </p>

                <div className="login-separator">
                    <span>or</span>
                </div>

                <GoogleLogin />

                {message.text && (
                    <div className={message.type === 'error' ? 'error-message' : 'success-message'}>
                        {message.text}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Login;
